// import Popper from "popper.js";

window.jQuery = $;
window.$ = $;

require("bootstrap");
require("jquery-stickytabs");
require("particles.js");
const Parallax = require('parallax-js')
require("vanilla-ripplejs");
require("lightgallery");
require("lg-zoom");

// Remove this demo code, that's only here to show how the .env file works!
// if (process.env["HELLO"]) {
//   console.log(`Hello ${process.env.HELLO}!`);
// }1

$(window).on('load', function() {
  $('.preloader').delay(200).fadeOut('normal');
})

$(function() {
  var scene = $('.stage').get(0);
  var parallaxInstance = new Parallax(scene);

  // $('a[href="#home"]').tab('show')
})

// $('.nav-tabs .nav-link').on('click', function(){
//       //   window.scrollTo(0, 0);
//       // document.body.scrollTop;
// })
// $('#myTab a').on('click', function(e) {
//   e.preventDefault();
//   $( '#topheader .navbar-nav' ).find( 'li.active' ).removeClass( 'active' );
//   $( this ).parent( 'li' ).addClass( 'active' );
// });



// function getQuery(url) {
//   var query = {},
//     href = url || window.location.href;
//     href.replace(/[?&](.+?)=([^&#]*)/g, function (_, key, value) {
//     query[key] = decodeURI(value).replace(/\+/g, ' ');
//   });
//   return query;
// }


// function getParam(name){
//     var obj = getQuery();
//     return obj[name];
// }

// $(document).ready(function() {
//   var t = getParam('t');
//   $('.nav a[href="#'+t+'"]').tab('show');
//   $(this).siblings().removeClass('active')

// });

$('.nav-tabs a').on('shown.bs.tab', function(){
  var p = $('.nav-tabs a.active').attr('href')
  $(p+'.tab-pane').siblings().removeClass('active show')
    // console.log(p)

})

// var t = setParam('t');
// $('.nav a[href="#'+t+'"]').on('show.bs.tab', function(){
//   // alert('New tab is now visible!');
//   // $('.nav a[href="#'+t+'"]').tab('show');
// });
history.scrollRestoration = 'manual';
$(document).ready(function() {
  var options = {
		backToTop: true
	};
	$('#myTab').stickyTabs( options );
  // $('#myTab').stickyTabs();
})
$(document).on('click', '.nav-tabs a', function(event) {
  event.preventDefault();
  history.pushState({}, '', this.href);
});
// $('.nav-tabs li a').click(function(e){
//   e.preventDefault();
//   e.stopImmediatePropagation();
//   $(this).tab('show');
// });

function viewConditions() {
  const pgn1 = 'home'
  const pgn2 = 'work'
  const pgn3 = 'about'
  const pgn4 = 'contact'

  const pg1 = $('#' + pgn1)
  const pg2 = $('#' + pgn2)
  const pg3 = $('#' + pgn3)
  const pg4 = $('#' + pgn4)

  const bd = $('body')

  if (pg1.hasClass('active')) {
    bd.addClass(pgn1)

    if(!window.location.hash) {
      var homeHash = $('a[href="#home"]').attr("href")
      window.location.hash = homeHash;
      // window.scrollTo(0, 0);
      // document.body.scrollTop;
    }
  }
  else {
    bd.removeClass(pgn1)
  }

  if (pg2.hasClass('active')) {
    bd.addClass(pgn2)
  }
  else {
    bd.removeClass(pgn2)
  }

  if (pg3.hasClass('active')) {
    bd.addClass(pgn3)
  }
  else {
    bd.removeClass(pgn3)
  }

  if (pg4.hasClass('active')) {
    bd.addClass(pgn4)
  }
  else {
    bd.removeClass(pgn4)
  }

  const lc = $('.logo-container')

  if (bd.hasClass(pgn1)) {
    lc.removeClass('active')
  }
  else {
    $(lc,':not(.active)').addClass('active')
  }
}
$(document).ready(viewConditions)

$('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
  viewConditions()
})

function recalculateCanvas(){
  var navbarheight = $('.nav-main').outerHeight();
  var logoheight = $('.logo-container').outerHeight();

  $('#home').css({'paddingBottom':  navbarheight + 'px'});
  $('.tab-pane.content').css({'bottom':  navbarheight + 'px', 'top':  logoheight + 'px'});
}
$(document).ready(recalculateCanvas)
$(window).resize(recalculateCanvas)




$('#modalLink-mo').on('click', function() {
  $(this).lightGallery({
    mode: 'lg-lollipop',
    loop: false,
    hideControlOnEnd: true,
    zoom: true,
    download: false,
    preload: 1,
    dynamic: true,
    dynamicEl: [{
      "src": 'dist/images/portfolio/mo-01.jpg'}, {
      'src': 'dist/images/portfolio/mo-02.jpg'}, {
      'src': 'dist/images/portfolio/mo-03.jpg'}, {
      'src': 'dist/images/portfolio/mo-04.jpg'}, {
      'src': 'dist/images/portfolio/mo-05.jpg'}, {
      'src': 'dist/images/portfolio/mo-06.jpg'}, {
      'src': 'dist/images/portfolio/mo-07.jpg'
    }]
  })
})
$('#modalLink-su').on('click', function() {
  $(this).lightGallery({
    mode: 'lg-lollipop',
    loop: false,
    hideControlOnEnd: true,
    zoom: true,
    download: false,
    preload: 1,
    dynamic: true,
    dynamicEl: [{
      "src": 'dist/images/portfolio/su-01.jpg'}, {
      'src': 'dist/images/portfolio/su-02.jpg'}, {
      'src': 'dist/images/portfolio/su-03.jpg'}, {
      'src': 'dist/images/portfolio/su-04.jpg'}, {
      'src': 'dist/images/portfolio/su-05.jpg'}, {
      'src': 'dist/images/portfolio/su-06.jpg'}, {
      'src': 'dist/images/portfolio/su-07.jpg'
    }]
  })
})


$('#modalLink-ni').on('click', function() {
  $(this).lightGallery({
    mode: 'lg-lollipop',
    loop: false,
    hideControlOnEnd: true,
    zoom: true,
    download: false,
    preload: 1,
    dynamic: true,
    dynamicEl: [{
      "src": 'dist/images/portfolio/ni01.jpg'}, {
      'src': 'dist/images/portfolio/ni02.jpg'}, {
      'src': 'dist/images/portfolio/ni03.jpg'}, {
      'src': 'dist/images/portfolio/ni04.jpg'}, {
      'src': 'dist/images/portfolio/ni05.jpg'}, {
      'src': 'dist/images/portfolio/ni06.jpg'}, {
      'src': 'dist/images/portfolio/ni07.jpg'}, {
      'src': 'dist/images/portfolio/ni08.jpg'}, {
      'src': 'dist/images/portfolio/ni09.jpg'}, {
      'src': 'dist/images/portfolio/ni10.jpg'
    }]
  })
})
$('#modalLink-mm').on('click', function() {
  $(this).lightGallery({
    mode: 'lg-lollipop',
    loop: false,
    hideControlOnEnd: true,
    zoom: true,
    download: false,
    preload: 1,
    dynamic: true,
    dynamicEl: [{
      "src": 'dist/images/portfolio/mm-01.jpg'}, {
      'src': 'dist/images/portfolio/mm-02.jpg'}, {
      'src': 'dist/images/portfolio/mm-03.jpg'}, {
      'src': 'dist/images/portfolio/mm-04.jpg'}, {
      'src': 'dist/images/portfolio/mm-05.jpg'}, {
      'src': 'dist/images/portfolio/mm-06.jpg'}, {
      'src': 'dist/images/portfolio/mm-07.jpg'}, {
      'src': 'dist/images/portfolio/mm-08.jpg'}, {
      'src': 'dist/images/portfolio/mm-09.jpg'}, {
      'src': 'dist/images/portfolio/mm-10.jpg'}, {
      'src': 'dist/images/portfolio/mm-11.jpg'
    }]
  })
})
$('#modalLink-jhp').on('click', function() {
  $(this).lightGallery({
    mode: 'lg-lollipop',
    loop: false,
    hideControlOnEnd: true,
    zoom: true,
    download: false,
    preload: 1,
    dynamic: true,
    dynamicEl: [{
      "src": 'dist/images/portfolio/jhp-1.jpg'}, {
      'src': 'dist/images/portfolio/jhp-2.jpg'}, {
      'src': 'dist/images/portfolio/jhp-3.jpg'}, {
      'src': 'dist/images/portfolio/jhp-4.jpg'}, {
      'src': 'dist/images/portfolio/jhp-5.jpg'}, {
      'src': 'dist/images/portfolio/jhp-6.jpg'}, {
      'src': 'dist/images/portfolio/jhp-7.jpg'}, {
      'src': 'dist/images/portfolio/jhp-8.jpg'}, {
      'src': 'dist/images/portfolio/jhp-9.jpg'
    }]
  })
})
$('#modalLink-mkdb').on('click', function() {
  $(this).lightGallery({
    mode: 'lg-lollipop',
    loop: false,
    hideControlOnEnd: true,
    zoom: true,
    download: false,
    preload: 1,
    dynamic: true,
    dynamicEl: [{
      "src": 'dist/images/portfolio/mkdb-1.jpg'}, {
      'src': 'dist/images/portfolio/mkdb-2.jpg'}, {
      'src': 'dist/images/portfolio/mkdb-3.jpg'}, {
      'src': 'dist/images/portfolio/mkdb-4.jpg'}, {
      'src': 'dist/images/portfolio/mkdb-5.jpg'}, {
      'src': 'dist/images/portfolio/mkdb-6.jpg'}, {
      'src': 'dist/images/portfolio/mkdb-7.jpg'}, {
      'src': 'dist/images/portfolio/mkdb-8.jpg'}, {
      'src': 'dist/images/portfolio/mkdb-9.jpg'}, {
      'src': 'dist/images/portfolio/mkdb-10.jpg'}, {
      'src': 'dist/images/portfolio/mkdb-11.jpg'}, {
      'src': 'dist/images/portfolio/mkdb-12.jpg'}, {
      'src': 'dist/images/portfolio/mkdb-13.jpg'}, {
      'src': 'dist/images/portfolio/mkdb-14.jpg'}, {
      'src': 'dist/images/portfolio/mkdb-15.jpg'}, {
      'src': 'dist/images/portfolio/mkdb-16.jpg'}, {
      'src': 'dist/images/portfolio/mkdb-17.jpg'}, {
      'src': 'dist/images/portfolio/mkdb-18.jpg'}, {
      'src': 'dist/images/portfolio/mkdb-19.jpg'
    }]
  })
})
$('#modalLink-tb').on('click', function() {
  $(this).lightGallery({
    mode: 'lg-lollipop',
    loop: false,
    hideControlOnEnd: true,
    zoom: true,
    download: false,
    preload: 1,
    dynamic: true,
    dynamicEl: [{
      "src": 'dist/images/portfolio/tb-1.jpg'}, {
      'src': 'dist/images/portfolio/tb-2.jpg'}, {
      'src': 'dist/images/portfolio/tb-3.jpg'
    }]
  })
})
$('#modalLink-fl').on('click', function() {
  $(this).lightGallery({
    mode: 'lg-lollipop',
    loop: false,
    hideControlOnEnd: true,
    zoom: true,
    download: false,
    preload: 1,
    dynamic: true,
    dynamicEl: [{
      "src": 'dist/images/portfolio/fl-1.jpg'}, {
      'src': 'dist/images/portfolio/fl-2.jpg'}, {
      'src': 'dist/images/portfolio/fl-3.jpg'}, {
      'src': 'dist/images/portfolio/fl-4.jpg'}, {
      'src': 'dist/images/portfolio/fl-5.jpg'}, {
      'src': 'dist/images/portfolio/fl-6.jpg'}, {
      'src': 'dist/images/portfolio/fl-7.jpg'}, {
      'src': 'dist/images/portfolio/fl-8.jpg'
    }]
  })
})
$('#modalLink-erm').on('click', function() {
  $(this).lightGallery({
    mode: 'lg-lollipop',
    loop: false,
    hideControlOnEnd: true,
    zoom: true,
    download: false,
    preload: 1,
    dynamic: true,
    dynamicEl: [{
      "src": 'dist/images/portfolio/erm-1.jpg'}, {
      'src': 'dist/images/portfolio/erm-2.jpg'}, {
      'src': 'dist/images/portfolio/erm-3.jpg'}, {
      'src': 'dist/images/portfolio/erm-4.jpg'}, {
      'src': 'dist/images/portfolio/erm-5.jpg'}, {
      'src': 'dist/images/portfolio/erm-6.jpg'
    }]
  })
})
$('#modalLink-vdolw').on('click', function() {
  $(this).lightGallery({
    mode: 'lg-lollipop',
    loop: false,
    hideControlOnEnd: true,
    zoom: true,
    download: false,
    preload: 1,
    dynamic: true,
    dynamicEl: [{
      "src": 'dist/images/portfolio/vdolw-1.jpg'}, {
      'src': 'dist/images/portfolio/vdolw-2.jpg'}, {
      'src': 'dist/images/portfolio/vdolw-3.jpg'
    }]
  })
})
$('#modalLink-bdwp').on('click', function() {
  $(this).lightGallery({
    mode: 'lg-lollipop',
    loop: false,
    hideControlOnEnd: true,
    zoom: true,
    download: false,
    preload: 1,
    dynamic: true,
    dynamicEl: [{
      "src": 'dist/images/portfolio/bdwp-1.jpg'}, {
      'src': 'dist/images/portfolio/bdwp-2.jpg'}, {
      'src': 'dist/images/portfolio/bdwp-3.jpg'}, {
      'src': 'dist/images/portfolio/bdwp-4.jpg'}, {
      'src': 'dist/images/portfolio/bdwp-5.jpg'}, {
      'src': 'dist/images/portfolio/bdwp-6.jpg'}, {
      'src': 'dist/images/portfolio/bdwp-7.jpg'}, {
      'src': 'dist/images/portfolio/bdwp-8.jpg'}, {
      'src': 'dist/images/portfolio/bdwp-9.jpg'}, {
      'src': 'dist/images/portfolio/bdwp-10.jpg'
    }]
  })
})
$('#modalLink-cfp').on('click', function() {
  $(this).lightGallery({
    mode: 'lg-lollipop',
    loop: false,
    hideControlOnEnd: true,
    zoom: true,
    download: false,
    preload: 1,
    dynamic: true,
    dynamicEl: [{
      "src": 'dist/images/portfolio/cfp-1.jpg'}, {
      'src': 'dist/images/portfolio/cfp-2.jpg'}, {
      'src': 'dist/images/portfolio/cfp-3.jpg'}, {
      'src': 'dist/images/portfolio/cfp-4.jpg'}, {
      'src': 'dist/images/portfolio/cfp-5.jpg'
    }]
  })
})
$('#modalLink-wap').on('click', function() {
  $(this).lightGallery({
    mode: 'lg-lollipop',
    loop: false,
    hideControlOnEnd: true,
    zoom: true,
    download: false,
    preload: 1,
    dynamic: true,
    dynamicEl: [{
      "src": 'dist/images/portfolio/wap-1.jpg'}, {
      'src': 'dist/images/portfolio/wap-2.jpg'}, {
      'src': 'dist/images/portfolio/wap-3.jpg'}, {
      'src': 'dist/images/portfolio/wap-4.jpg'}, {
      'src': 'dist/images/portfolio/wap-5.jpg'
    }]
  })
})
$('#modalLink-mima').on('click', function() {
  $(this).lightGallery({
    mode: 'lg-lollipop',
    loop: false,
    hideControlOnEnd: true,
    zoom: true,
    download: false,
    preload: 1,
    dynamic: true,
    dynamicEl: [{
      "src": 'dist/images/portfolio/mima-1.jpg'}, {
      'src': 'dist/images/portfolio/mima-2.jpg'}, {
      'src': 'dist/images/portfolio/mima-3.jpg'}, {
      'src': 'dist/images/portfolio/mima-4.jpg'}, {
      'src': 'dist/images/portfolio/mima-5.jpg'}, {
      'src': 'dist/images/portfolio/mima-6.jpg'}, {
      'src': 'dist/images/portfolio/mima-7.jpg'}, {
      'src': 'dist/images/portfolio/mima-8.jpg'
    }]
  })
})

particlesJS("stars", {
  particles: {
    number: { value: 80, density: { enable: false, value_area: 800 } },
    color: { value: "#ffffff" },
    shape: {
      type: "circle",
      stroke: { width: 0, color: "#000000" },
      polygon: { nb_sides: 5 },
      image: { src: "img/github.svg", width: 100, height: 100 }
    },
    opacity: {
      value: 1,
      random: true,
      anim: { enable: true, speed: 5, opacity_min: 0, sync: false }
    },
    size: {
      value: 2,
      random: true,
      anim: { enable: true, speed: 5, size_min: 0.1, sync: false }
    },
    line_linked: {
      enable: false,
      distance: 150,
      color: "#ffffff",
      opacity: 0.4,
      width: 1
    },
    move: {
      enable: true,
      speed: 0,
      direction: "none",
      random: true,
      straight: true,
      out_mode: "out",
      bounce: false,
      attract: { enable: false, rotateX: 600, rotateY: 600 }
    }
  },
  // interactivity: {
  //   detect_on: "window",
  //   events: {
  //     onhover: { enable: true, mode: "grab" },
  //     resize: true
  //   },
  //   modes: {
  //     grab: { distance: 350, line_linked: { opacity: 0.6 } }
  //   }
  // },
  retina_detect: true
})

particlesJS("meteorites", {
  particles: {
    number: { value: 2, density: { enable: false, value_area: 800 } },
    color: { value: "#fff" },
    shape: {
      type: "circle",
      stroke: { width: 0, color: "#000000" },
      polygon: { nb_sides: 5 },
      image: { src: "img/github.svg", width: 100, height: 100 }
    },
    opacity: {
      value: 1,
      random: true,
      anim: { enable: true, speed: 4, opacity_min: 0.5, sync: false }
    },
    size: {
      value: 2,
      random: true,
      anim: { enable: false, speed: 1, size_min: 1, sync: true }
    },
    line_linked: {
      enable: false,
      distance: 500,
      color: "#ffffff",
      opacity: 0.4,
      width: 2
    },
    move: {
      enable: true,
      speed: 15,
      direction: "bottom-left",
      random: false,
      straight: true,
      out_mode: "out",
      bounce: false,
    }
  },
  interactivity: {
    detect_on: "canvas",
    events: {
      onhover: { enable: false},
      resize: true
    },
  },
  retina_detect: true
})

particlesJS("dust", {
  particles: {
    number: { value: 400, density: { enable: true, value_area: 800 } },
    color: { value: "#fff" },
    shape: {
      type: "circle",
      stroke: { width: 0, color: "#000000" },
      polygon: { nb_sides: 5 },
      image: { src: "img/github.svg", width: 100, height: 100 }
    },
    opacity: {
      value: 0.4,
      random: true,
      anim: { enable: false, speed: 1, opacity_min: 0.1, sync: false }
    },
    size: {
      value: 1.25,
      random: true,
      anim: { enable: false, speed: 40, size_min: 0.1, sync: false }
    },
    line_linked: {
      enable: false,
      distance: 500,
      color: "#ffffff",
      opacity: 0.4,
      width: 2
    },
    move: {
      enable: true,
      speed: 7,
      direction: "right",
      random: true,
      straight: false,
      out_mode: "out",
      bounce: false,
    }

  },
  interactivity: {
    detect_on: "canvas",
    events: {
      onhover: { enable: false},
      resize: true
    },
  },
  retina_detect: true
})


$(function() {
	$('textarea[max-rows]').each(function () {
		var textarea = $(this);

		var minRows = Number(textarea.attr('rows'));
		var maxRows = Number(textarea.attr('max-rows'));

		// clone the textarea and hide it offscreen
		// TODO: copy all the styles
		var textareaClone = $('<textarea/>', {
			rows: minRows,
			maxRows: maxRows,
			class: textarea.attr('class')
		}).css({
			position: 'absolute',
			left: -$(document).width() * 2
		}).insertAfter(textarea);

		var textareaCloneNode = textareaClone.get(0);

		textarea.on('input', function () {
			// copy the input from the real textarea
			textareaClone.val(textarea.val());

			// set as small as possible to get the real scroll height
			textareaClone.attr('rows', 1);

			// save the real scroll height
			var scrollHeight = textareaCloneNode.scrollHeight;

			// increase the number of rows until the content fits
			for (var rows = minRows; rows < maxRows; rows++) {
				textareaClone.attr('rows', rows);

				if (textareaClone.height() > scrollHeight) {
					break;
				}
			}

			// copy the rows value back to the real textarea
			textarea.attr('rows', textareaClone.attr('rows'));
		}).trigger('input');
	});
});

